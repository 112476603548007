<op-attachment-list
  [ngClass]="{
    'op-attachments--list': true,
    'op-attachments--list_dragging': dragging
  }"
  *ngIf="(attachments$ | async).length"
  [attachments]="attachments$ | async"
  [collectionKey]="collectionKey"
  [allowUploading]="allowUploading"
></op-attachment-list>

<input
  *ngIf="allowUploading && resource.canAddAttachments"
  #hiddenFileInput
  type="file"
  id="attachment_files"
  name="attachment_files"
  (change)="onFilePickerChanged()"
  hidden
  multiple
/>

<div
  *ngIf="allowUploading && resource.canAddAttachments && (attachments$ | async).length"
  class="op-attachments--actions"
>
  <button
    type="button"
    class="spot-link"
    (click)="triggerFileInput($event)"
    data-qa-selector="op-attachments--upload-button"
  >
    <span class="spot-icon spot-icon_attachment"></span>

    Add attachment
  </button>
</div>

<button
  *ngIf="allowUploading && resource.canAddAttachments"
  [attr.aria-label]="text.uploadLabel"
  data-qa-selector="op-attachments--drop-box"
  [ngClass]="{
    'hide-when-print': true,
    'op-attachments--drop-box': true,
    'op-attachments--drop-box_dragging': dragging,
    'op-attachments--drop-box_dragging-over': draggingOverDropZone,
    'op-attachments--drop-box_float': (attachments$ | async).length
  }"
  type="button"
  (click)="triggerFileInput()"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDropFiles($event)"
>
  <span
    class="spot-icon spot-icon_attachment op-attachments--drop-box-icon"
  ></span>
  <label
    for="attachment_files"
    class="op-attachments--drop-box-text"
  >
    {{ text.dropFiles }}<br>
    {{ text.dropFilesHint }}
  </label>
</button>
